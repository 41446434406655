<template>
    <div class="w-full">
      <!-- create form -->
      <vs-popup title="Créer: Opportunité" fullscreen :active.sync="popupCreateOpportunite">
        <div class="relative w-full">
          <div class="w-full mb-20">
            <div class="w-full flex justify-end">
              <span class="text-danger mr-1">* </span> = informations requises
            </div>
            <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur les opportunités</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <div v-if="nom_compte && id_compte" class="w-full h-16 rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                      <div class="w-full h-full flex justify-between items-center rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                          <div>
                              <span class="">{{ nom_compte }}</span>
                          </div>
                          <div @click="nom_compte = null;id_compte = null" class="cursor-pointer hover:underline">
                              <span class="text-danger">supprimer</span>
                          </div>
                      </div>
                  </div>
                  <div v-else>
                    <label class="w-full" for="nom_compte"><span class="text-danger">*</span>Nom du compte</label>
                    <vs-input class="w-full" @input="searchCompte()" @focus="search_compte_focus = true" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherche de comptes correspondants" name="search_compte" v-model="search_compte" />
                    <vs-input class="w-full hidden" type="text" label="" placeholder="" name="nom_compte" v-validate="'required'" v-model="nom_compte" />
                    <div v-if="search_compte_focus" @click="search_compte_focus = true" @mouseover="search_compte_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;">
                      <ul>
                          <li @click="selectCompte(compte)" class="hover:bg-grey-light hover:text-black cursor-pointer px-2 py-3" style="" :key="index" v-for="(compte, index) in search_compte_result">
                              <span>{{ compte.nom }}</span>
                          </li>
                      </ul>
                    </div>
                    <span class="text-danger text-sm" v-show="errors.has('nom_compte')">{{ errors.first('nom_compte') }}</span>
                  </div>
                </div>
                <div class="w-full mt-5">
                  <label class="w-full text-black font-semibold" for="nom_proprietaire">*Propriétaire de l'opportunité</label>
                  <div>
                    <span class="mr-2">{{ activeUserInfos ? activeUserInfos.prenoms : '' }}</span>
                    <span>{{ activeUserInfos ? activeUserInfos.nom : '' }}</span>
                  </div>
                </div>
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="*Nom de l'opportunité" placeholder="" name="nom" v-validate="'required'" v-model="nom" />
                  <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                  <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                  <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="*Étape" autocomplete name="etape" v-validate="'required'" v-model="etape" @change="onEtapeChange()" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Qualifiée','Analyse','Proposition','Négociation','Fermée gagnée','Fermée perdue']" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('etape')">{{ errors.first('etape') }}</span>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="Probabilité (%)" placeholder="" name="probabilte" v-validate="'required|numeric'" v-model="probabilte" />
                  <span class="text-danger text-sm" v-show="errors.has('probabilte')">{{ errors.first('probabilte') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="*Catégorie de prévision" autocomplete name="categorie_prevision" v-validate="'required'" v-model="categorie_prevision" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Omis','Pipeline','Meilleur cas','Engagement','Fermé']" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('categorie_prevision')">{{ errors.first('categorie_prevision') }}</span>
                </div>
                <div class="w-full flex mt-5">
                  <vs-input class="w-full" type="text" label="Étape suivante" placeholder="" name="etape_suivante" v-model="etape_suivante" />
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations complémentaires</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="Type" autocomplete name="type_opportunite" v-model="type_opportunite" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Business existants','Nouveau Business']" />
                  </vs-select>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="Origine de la piste" autocomplete name="origine" v-model="origine" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Liste des achats','Salon professionnel','Webinaire','Site web','Autre']" />
                  </vs-select>
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur la description</div>
            </div>
            <div class="w-full px-5">
              <div class="w-full mt-5">
                <vs-textarea label="Description" v-model="description" />
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Autres informations</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <label class="w-full" for="nom_compte">Contrat</label>
                  <vs-input class="w-full" @focus="search_contrat_focus = true" @blur="search_contrat_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Contrats..." name="search_contrat" v-model="search_contrat" />
                  <div v-if="search_contrat_focus" @click="search_contrat_focus = true" @mouseover="search_contrat_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <label class="w-full" for="nom_compte">Devis synchronisé</label>
                  <vs-input class="w-full" @focus="search_devis_focus = true" @blur="search_devis_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Devis..." name="search_devis" v-model="search_devis" />
                  <div v-if="search_devis_focus" @click="search_devis_focus = true" @mouseover="search_devis_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur le système</div>
            </div>
          </div>
          <div class="fixed w-full left-0 bottom-0 flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
            <vs-button @click="popupCreateOpportunite=false" class="mr-2" color="danger" type="border">Annuler</vs-button>
            <vs-button @click="createOpportuniteAndNew()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
            <vs-button @click="createOpportunite()">Enregistrer</vs-button> 
          </div>
        </div>
      </vs-popup>
      <!-- /create form -->
      <!-- update form -->
      <vs-popup :title="`Modifier ${nom}`" fullscreen :active.sync="popupUpdateOpportunite">
        <div class="relative w-full">
          <div class="w-full mb-20">
            <div class="w-full flex justify-end">
              <span class="text-danger mr-1">* </span> = informations requises
            </div>
            <div class="w-full h-12 px-2 mt-2 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur les opportunités</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <div v-if="nom_compte && id_compte" class="w-full h-16 rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                      <div class="w-full h-full flex justify-between items-center rounded-lg p-2" style="border: 1px #dcdcdc solid;">
                          <div>
                              <span class="">{{ nom_compte }}</span>
                          </div>
                          <div @click="nom_compte = null;id_compte = null" class="cursor-pointer hover:underline">
                              <span class="text-danger">supprimer</span>
                          </div>
                      </div>
                  </div>
                  <div v-else>
                    <label class="w-full" for="nom_compte"><span class="text-danger">*</span>Nom du compte</label>
                    <vs-input class="w-full" @input="searchCompte()" @focus="search_compte_focus = true" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherche de comptes correspondants" name="search_compte" v-model="search_compte" />
                    <vs-input class="w-full hidden" type="text" label="" placeholder="" name="nom_compte" v-validate="'required'" v-model="nom_compte" />
                    <div v-if="search_compte_focus" @click="search_compte_focus = true" @mouseover="search_compte_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;">
                      <ul>
                          <li @click="selectCompte(compte)" class="hover:bg-grey-light hover:text-black cursor-pointer px-2 py-3" style="" :key="index" v-for="(compte, index) in search_compte_result">
                              <span>{{ compte.nom }}</span>
                          </li>
                      </ul>
                    </div>
                    <span class="text-danger text-sm" v-show="errors.has('nom_compte')">{{ errors.first('nom_compte') }}</span>
                  </div>
                </div>
                <div class="w-full mt-5">
                  <label class="w-full text-black font-semibold" for="nom_proprietaire">*Propriétaire de l'opportunité</label>
                  <div>
                    <span class="mr-2">{{ activeUserInfos ? activeUserInfos.prenoms : '' }}</span>
                    <span>{{ activeUserInfos ? activeUserInfos.nom : '' }}</span>
                  </div>
                </div>
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="*Nom de l'opportunité" placeholder="" name="nom" v-validate="'required'" v-model="nom" />
                  <span class="text-danger text-sm" v-show="errors.has('nom')">{{ errors.first('nom') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="number" label="Montant" placeholder="" name="montant" v-validate="'numeric'" v-model="montant" />
                  <span class="text-danger text-sm" v-show="errors.has('montant')">{{ errors.first('montant') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="date" label="*Date de clôture" placeholder="" name="date_cloture" v-validate="'required'" v-model="date_cloture" />
                  <span class="text-danger text-sm" v-show="errors.has('date_cloture')">{{ errors.first('date_cloture') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="*Étape" autocomplete name="etape" v-validate="'required'" v-model="etape" @change="onEtapeChange()" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Qualifiée','Analyse','Proposition','Négociation','Fermée gagnée','Fermée perdue']" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('etape')">{{ errors.first('etape') }}</span>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <vs-input class="w-full" type="text" label="Probabilité (%)" placeholder="" name="probabilte" v-validate="'required|numeric'" v-model="probabilte" />
                  <span class="text-danger text-sm" v-show="errors.has('probabilte')">{{ errors.first('probabilte') }}</span>
                </div>
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="*Catégorie de prévision" autocomplete name="categorie_prevision" v-validate="'required'" v-model="categorie_prevision" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Omis','Pipeline','Meilleur cas','Engagement','Fermé']" />
                  </vs-select>
                  <span class="text-danger text-sm" v-show="errors.has('categorie_prevision')">{{ errors.first('categorie_prevision') }}</span>
                </div>
                <div class="w-full flex mt-5">
                  <vs-input class="w-full" type="text" label="Étape suivante" placeholder="" name="etape_suivante" v-model="etape_suivante" />
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations complémentaires</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="Type" autocomplete name="type_opportunite" v-model="type_opportunite" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Business existants','Nouveau Business']" />
                  </vs-select>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <vs-select class="w-full" label="Origine de la piste" autocomplete name="origine" v-model="origine" >
                    <vs-select-item :key="index" :value="c" :text="c" v-for="(c, index) in ['Publicité','Événement client','Recommandation d\'un employé','Référence externe','Google AdWords','Partenaire','Liste des achats','Salon professionnel','Webinaire','Site web','Autre']" />
                  </vs-select>
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur la description</div>
            </div>
            <div class="w-full px-5">
              <div class="w-full mt-5">
                <vs-textarea label="Description" v-model="description" />
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Autres informations</div>
            </div>
            <div class="w-full flex px-5">
              <div class="w-1/2 flex flex-col mr-10">
                <div class="w-full mt-5">
                  <label class="w-full" for="nom_compte">Contrat</label>
                  <vs-input class="w-full" @focus="search_contrat_focus = true" @blur="search_contrat_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Contrats..." name="search_contrat" v-model="search_contrat" />
                  <div v-if="search_contrat_focus" @click="search_contrat_focus = true" @mouseover="search_contrat_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                </div>
              </div>
              <div class="w-1/2">
                <div class="w-full mt-5">
                  <label class="w-full" for="nom_compte">Devis synchronisé</label>
                  <vs-input class="w-full" @focus="search_devis_focus = true" @blur="search_devis_focus = false" icon-pack="feather" icon="icon-search" icon-after placeholder="Recherchez dans les Devis..." name="search_devis" v-model="search_devis" />
                  <div v-if="search_devis_focus" @click="search_devis_focus = true" @mouseover="search_devis_focus = true" class="w-full bg-white shadow-md rounded-lg mt-1" style="border: 1px #dcdcdc solid; min-height: 30px;"></div>
                </div>
              </div>
            </div>
            <div class="w-full h-12 px-2 mt-5 font-semibold" style="display: flex;flex-direction: row; align-items: center ;background-color: rgb(236, 236, 236); border-radius: 5px;">
              <div>Informations sur le système</div>
            </div>
            <div class="w-full flex px-5 py-3">
              <div class="w-1/2 mr-10">
                <span class="font-semibold mr-5">Créé par:</span>
                <span class="mr-1">
                  {{ proprietaire ? `${proprietaire.nom},` : '' }}
                </span>
                <span>
                  {{ formateDate(createdAt) }}
                </span>
              </div>
              <div class="w-1/2">
                <span class="font-semibold mr-5">Dernière modification par:</span>
                <span class="mr-1">
                  {{ proprietaire ? `${proprietaire.nom},` : '' }}
                </span>
                <span>{{ formateDate(updatedAt) }}</span>
              </div>
            </div>
          </div>
          <div class="fixed w-full left-0 bottom-0 flex justify-center border-t mt-5 py-5" style="background-color: rgb(236, 236, 236);">
            <vs-button @click="showUpdateOpportuniteModal(null)" class="mr-2" color="danger" type="border">Annuler</vs-button>
            <vs-button @click="updateOpportunite()" class="mr-2" color="primary" type="border">Enregistrer et Nouveau</vs-button>
            <vs-button @click="updateOpportunite()">Enregistrer</vs-button> 
          </div>
        </div>
      </vs-popup>
      <!-- /update form -->
      <vx-card>
        <vs-table noDataText="Aucune donnée" stripe v-model="selecteOpportunites" pagination max-items="50" search :data="opportunitesData">
          <template slot="header">
            <vs-button type="border" @click="popupCreateOpportunite=true" class="mb-4 mr-5 md:mb-0">Nouveau</vs-button>
          </template>
          <template slot="thead">
            <vs-th>Nom de l'opportunité</vs-th>
            <vs-th>Nom du compte</vs-th>
            <vs-th>Étape</vs-th>
            <vs-th>Date de clôture</vs-th>
            <vs-th>propriétaire</vs-th>
            <vs-th></vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="hover:underline text-primary">
                  <span @click="viewOpportunite(tr.slug)">
                    {{ tr.nom }}
                  </span>
                </vs-td>
                <vs-td class="hover:underline text-primary">{{ tr.nom_compte }}</vs-td>
                <vs-td>{{ tr.etape }}</vs-td>
                <vs-td>{{ formateDate(tr.date_cloture) }}</vs-td>
                <vs-td>{{ tr.nom_proprietaire }}</vs-td>
                <vs-td>
                  <feather-icon @click="showUpdateOpportuniteModal(tr)" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" />
                  <feather-icon @click="confirmDeleteOpportunite(tr._id)" icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" />
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </vx-card>
    </div>
</template>
<script>
// For custom error message 
import { Validator } from 'vee-validate' 
const dict = { 
  custom: { 
    nom_compte: { required: 'Remplissez le champ Nom du compte.', alpha: '' },
    nom: { required: 'Remplissez le champ Nom de l\'opportunité.', alpha: '' }, 
    date_cloture: { required: 'Remplissez le champ Date de clôture.', alpha: '' },
    etape: { required: 'Remplissez le champ Étape.', alpha: '' },
    categorie_prevision: { required: 'Remplissez le champ Catégorie de prévision.', alpha: '' }
  } 
}
// register custom messages 
Validator.localize('fr', dict)
export default {
  name: 'Opportunite',
  components: {
  },
  data () {
    return {
      activeUserInfos: null,
      popupCreateOpportunite: false,
      popupUpdateOpportunite: false,
      selecteOpportunites: [],

      id_opportunite: null,
      proprietaire: null,
      id_proprietaire: null,
      nom_proprietaire: null,
      id_compte: null,
      nom_compte: null,
      id_suspect: null,
      nom: null,
      montant: 0,
      date_cloture: null,
      etape: 'Qualifiée',
      probabilte: 10,
      categorie_prevision: 'Pipeline',
      etape_suivante: null,
      type_opportunite: '',
      origine: null,
      description: null,
      contrats: [],
      devis: [],

      createdAt: null,
      updatedAt: null,
      historique_update: [],

      search_compte: null,
      search_compte_focus: false,
      search_compte_result: [],

      search_contrat: null,
      search_contrat_focus: false,
      search_contrat_result: [],

      search_devis: null,
      search_devis_focus: false,
      search_devis_result: [],

      //import for excel
      tableData: [],
      header: [],
      sheetName: ''
    }
  },
  computed: {
    opportunitesData () {
      return this.$store.state.opportunite.opportunites
    }
  },
  methods: {
    viewOpportunite (slug) {
      this.$router.push(`/commercial/opportunite/${slug}`)
    },
    formateDate (date) {
      const today = new Date(date)
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1 // Months start at 0!
      let dd = today.getDate()

      if (dd < 10) dd = `0${  dd}`
      if (mm < 10) mm = `0${  mm}`

      return `${dd  }/${  mm  }/${  yyyy}`
    },
    formatDateForInputDate (date) {
      const d = new Date(date)
      let month = `${  d.getMonth() + 1}`
      let day = `${  d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${  month}`
      if (day.length < 2) day = `0${  day}`

      return [year, month, day].join('-')
    },
    onEtapeChange () {
      if (this.etape === 'Qualifiée') {
        this.probabilte = 10
        this.categorie_prevision = 'Pipeline'
      }
      if (this.etape === 'Analyse des besoins') {
        this.probabilte = 35
        this.categorie_prevision = 'Pipeline'
      }
      if (this.etape === 'Proposition') {
        this.probabilte = 75
        this.categorie_prevision = 'Meilleur cas'
      }
      if (this.etape === 'Négociation') {
        this.probabilte = 90
        this.categorie_prevision = 'Engagement'
      }
      if (this.etape === 'Fermée gagnée') {
        this.probabilte = 100
        this.categorie_prevision = 'Fermé'
      }
      if (this.etape === 'Fermée perdue') {
        this.probabilte = 0
        this.categorie_prevision = 'Omis'
      }
    },
    searchCompte () {
      if (this.search_compte !== null || this.search_compte !== undefined || this.search_compte !== '') {
        this.$store.dispatch('compte/searchCompte', this.search_compte)
          .then((response) => {
            this.search_compte_result = response.data.comptes
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Nous avons rencontré un problème',
              text: `${err}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
          })
      }
    },
    selectCompte (compte) {
      this.id_compte = compte._id
      this.nom_compte = compte.nom
    },
    showUpdateOpportuniteModal (opportunite) {
      this.id_opportunite = opportunite ? opportunite._id : null
      this.proprietaire = opportunite ? {
        id: opportunite.id_proprietaire,
        nom: opportunite.nom_proprietaire
      } : null
      this.id_compte = opportunite ? opportunite.id_compte : null
      this.nom_compte = opportunite ? opportunite.nom_compte : null
      this.nom = opportunite ? opportunite.nom : null
      this.montant = opportunite ? opportunite.montant : 0
      this.date_cloture = opportunite ? this.formatDateForInputDate(opportunite.date_cloture) : null
      this.etape = opportunite ? opportunite.etape : 'Qualifiée'
      this.probabilte = opportunite ? opportunite.probabilte : 10
      this.categorie_prevision = opportunite ? opportunite.categorie_prevision : 'Pipeline'
      this.etape_suivante = opportunite ? opportunite.etape_suivante : null
      this.type_opportunite = opportunite ? opportunite.type_opportunite : null
      this.origine = opportunite ? opportunite.origine : null
      this.description = opportunite ? opportunite.description : null
      this.contrats = opportunite ? opportunite.etape_suivante : []
      this.devis = opportunite ? opportunite.etape_suivante : []
      this.createdAt = opportunite ? opportunite.createdAt : null
      this.updatedAt = opportunite ? opportunite.updatedAt : null

      this.popupUpdateOpportunite = !this.popupUpdateOpportunite
    },
    createOpportunite () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            id_compte: this.id_compte,
            nom_compte: this.nom_compte,
            nom: this.nom,
            montant: this.montant ? parseInt(this.montant) : 0,
            date_cloture: this.date_cloture,
            etape: this.etape,
            probabilte: this.probabilte,
            categorie_prevision: this.categorie_prevision,
            etape_suivante: this.etape_suivante,
            type_opportunite: this.type_opportunite,
            origine: this.origine,
            description: this.description,
            contrats: this.contrats,
            devis: this.devis
          }
          this.$vs.loading({
            type:'sound'
          })

          this.$store.dispatch('opportunite/createOpportunite', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.reset_data()
              this.popupCreateOpportunite = false
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Nous avons rencontré un problème',
                text: `${err}`,
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          console.log(result)
        }

      })
    },
    createOpportuniteAndNew () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            id_compte: this.id_compte,
            nom_compte: this.nom_compte,
            nom: this.nom,
            montant: this.montant ? parseInt(this.montant) : 0,
            date_cloture: this.date_cloture,
            etape: this.etape,
            probabilte: this.probabilte,
            categorie_prevision: this.categorie_prevision,
            etape_suivante: this.etape_suivante,
            type_opportunite: this.type_opportunite,
            origine: this.origine,
            description: this.description,
            contrats: this.contrats,
            devis: this.devis
          }
          this.$vs.loading({
            type:'sound'
          })

          this.$store.dispatch('opportunite/createOpportunite', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.reset_data()
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Nous avons rencontré un problème',
                text: `${err}`,
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        }

      })
    },
    updateOpportunite () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const payload = {
            id: this.id_opportunite,
            id_user: this.activeUserInfos ? this.activeUserInfos._id : null,
            id_proprietaire: this.activeUserInfos ? this.activeUserInfos._id : null,
            nom_proprietaire: this.activeUserInfos ? `${this.activeUserInfos.nom} ${this.activeUserInfos.prenoms}` : null,
            id_compte: this.id_compte,
            nom_compte: this.nom_compte,
            nom: this.nom,
            montant: this.montant ? parseInt(this.montant) : 0,
            date_cloture: this.date_cloture,
            etape: this.etape,
            probabilte: this.probabilte,
            categorie_prevision: this.categorie_prevision,
            etape_suivante: this.etape_suivante,
            type_opportunite: this.type_opportunite,
            origine: this.origine,
            description: this.description,
            contrats: this.contrats,
            devis: this.devis
          }
          console.log('payload: ', payload)
          this.$vs.loading({
            type:'sound'
          })

          this.$store.dispatch('opportunite/updateOpportunite', payload)
            .then(() => {
              this.$vs.notify({
                title: 'success',
                text: 'enregistrement valider',
                iconPack: 'feather',
                icon: 'icon-check',
                color: 'primary'
              })
              this.reset_data()
              this.popupUpdateOpportunite = !this.popupUpdateOpportunite
              this.$vs.loading.close()
            })
            .catch(err => {
              console.error(err)
              this.$vs.loading.close()
              this.$vs.notify({
                title: 'Nous avons rencontré un problème',
                text: `${err}`,
                iconPack: 'feather',
                icon: 'icon-error',
                color: 'danger'
              })
            })
        } else {
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: 'Réviser les champs obligatoires(*)',
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        }

      })
    },
    confirmDeleteOpportunite (id) {
      this.id_opportunite = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer l\'opportunité',
        text: 'Voulez-vous vraiment supprimer cette opportunité ?',
        acceptText: 'Supprimer',
        accept: this.softdeleteOpportunite
      })
    },
    softdeleteOpportunite () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('opportunite/softdeleteOpportunite', this.id_opportunite)
        .then(() => {
          this.$vs.notify({
            title: 'Suspect',
            text: 'L\'opportunité a été supprimé(e)',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Nous avons rencontré un problème',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    reset_data () {
      this.id_compte = null
      this.nom_compte = null
      this.nom = null
      this.montant = 0
      this.date_cloture = null
      this.etape = 'Qualifiée'
      this.probabilte = 10
      this.categorie_prevision = 'Pipeline'
      this.etape_suivante = null
      this.type_opportunite = null
      this.origine = null
      this.description = null
      this.contrats = []
      this.devis = []
    },
    getOpportunitesByUser () {
      this.$vs.loading({
        type:'sound'
      })
      const id = JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : null
      if (id) {
        this.$store.dispatch('opportunite/getOpportunitesByUser', id)
          .then(() => {
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
          })
      } else {
        this.$vs.loading.close()
        this.$vs.notify({
          title: 'Nous avons rencontré un problème',
          text: 'Impossible de charger la liste des opportunités récemment visualisés',
          iconPack: 'feather',
          icon: 'icon-error',
          color: 'danger'
        })
      }
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created () {
    this.activeUserInfo()
    this.getOpportunitesByUser()
  }
}
</script>